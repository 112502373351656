import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';

const CustomErrorComponent = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    return <Error statusCode={props.statusCode} />;
};

CustomErrorComponent.getInitialProps = async (contextData) => {
    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    await Sentry.captureUnderscoreErrorException(contextData);

    // This will contain the status code of the response
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Error.getInitialProps(contextData);
};

export default CustomErrorComponent;
